:root {
	/* Colors */
	--background: #ffffff;
	--foreground: #171717;
	--gray-100: #f8f8f8;
	--gray-200: #f5f5f5;
	--gray-300: #e5e5e5;
	--gray-400: #ddd;
	--gray-500: #999;
	--gray-600: #777;
	--gray-700: #555;
	--gray-800: #444;
	--gray-900: #333;

	/* Primary colors */
	--primary: #007bff;
	--primary-hover: #0056b3;
	--primary-dark: #004494;

	/* Spacing */
	--space-1: 4px;
	--space-2: 8px;
	--space-3: 16px;
	--space-4: 20px;
	--space-5: 32px;

	/* Border */
	--border-radius-sm: 4px;
	--border-radius-lg: 8px;
	--border-color: var(--gray-400);

	/* Shadow */
	--shadow-sm: 0 2px 4px rgba(0, 0, 0, 0.05);
	--shadow-md: 0 4px 8px rgba(0, 0, 0, 0.1);

	/* Transitions */
	--transition-base: all 0.2s ease;
	--transition-slow: all 0.3s ease-out;

	--text-color: var(--foreground);

	--brand-color: #fe3f7f;
	--brand-color-trans: rgba(254, 63, 127, 0.8);
}

[data-theme="light"] {
	--background: #f8f8f8;
	--foreground: #000;
	--gray-100: #f8f8f8;
	--gray-200: #f5f5f5;
	--gray-300: #e5e5e5;
	--gray-400: #ddd;
	--gray-500: #999;
	--gray-600: #777;
	--gray-700: #555;
	--gray-800: #444;
	--gray-900: #333;

	--primary: #007bff;
	--primary-hover: #0056b3;
	--primary-dark: #004494;

	--border-color: var(--gray-400);
	--shadow-sm: 0 2px 4px rgba(0, 0, 0, 0.05);
	--shadow-md: 0 4px 8px rgba(0, 0, 0, 0.1);

	--text-color: var(--foreground);
}

[data-theme="dark"] {
	--background: #2a2a2a;
	--foreground: #fff;
	--gray-100: #2a2a2a;
	--gray-200: #333;
	--gray-300: #3a3a3a;
	--gray-400: #444;
	--gray-500: #666;
	--gray-600: #888;
	--gray-700: #aaa;
	--gray-800: #ccc;
	--gray-900: #eee;

	--primary: #0056b3;
	--primary-hover: #004494;
	--primary-dark: #003371;

	--border-color: var(--gray-800);
	--shadow-sm: 0 2px 4px rgba(0, 0, 0, 0.2);
	--shadow-md: 0 4px 8px rgba(0, 0, 0, 0.3);

	--text-color: var(--foreground);
}

html,
body {
	max-width: 100vw;
	overflow-x: hidden;
}

body {
	color: var(--foreground);
	background: var(--background);
	font-family: Arial, Helvetica, sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	scrollbar-width: thin;
	scrollbar-color: var(--gray-400) var(--gray-100);
	overflow-x: hidden;
	scroll-behavior: smooth;
}

body::-webkit-scrollbar {
	width: 8px;
	height: 8px;
}

body::-webkit-scrollbar-thumb {
	background-color: var(--gray-400);
	border-radius: 4px;
}

body::-webkit-scrollbar-track {
	background-color: var(--gray-100);
}

[data-theme="dark"] {
	scrollbar-color: var(--gray-600) var(--gray-300);
}

[data-theme="dark"] body::-webkit-scrollbar-thumb {
	background-color: var(--gray-600);
}

[data-theme="dark"] body::-webkit-scrollbar-track {
	background-color: var(--gray-300);
}

* {
	box-sizing: border-box;
	padding: 0;
	margin: 0;
}

a {
	color: inherit;
	text-decoration: none;
}

button {
	padding: var(--space-2) var(--space-3);
	border: 1px solid var(--border-color);
	border-radius: var(--border-radius-sm);
	background: var(--gray-100);
	color: var(--gray-900);
	cursor: pointer;
	font-size: 0.9em;
	transition: var(--transition-base);
}

button:hover {
	background: var(--gray-300);
	border-color: var(--gray-400);
}

button.primary {
	background: var(--primary);
	color: var(--background);
	border-color: var(--primary-hover);
}

button.primary:hover {
	background: var(--primary-hover);
	border-color: var(--primary-dark);
}

a.primary {
	background: var(--primary);
	color: var(--background);
	border-color: var(--primary-hover);
}

a.primary:hover {
	background: var(--primary-hover);
	border-color: var(--primary-dark);
}

a.secondary {
	border-color: var(--gray-alpha-200);
	min-width: 180px;
}

a.secondary:hover {
	background: var(--button-secondary-hover);
	border-color: transparent;
}

.layer-opacity {
	display: flex;
	align-items: center;
	gap: var(--space-2);
	margin-top: var(--space-2);
	width: 100%;
	box-sizing: border-box;
	overflow: hidden; /* Prevent children from overflowing */
}

.opacity-number-input-container {
	display: flex;
	flex-direction: column; /* Stack buttons vertically */
	align-items: stretch; /* Stretch to match input width */
	margin: 0;
	padding: 0;
	width: 40px; /* Ensure fixed width is applied globally */
}

.opacity-button {
	background: none;
	border: none;
	cursor: pointer;
	padding: 0;
	font-size: 1em;
	color: var(--primary-color);
	width: 100%; /* Occupy full container width */
	box-sizing: border-box;
	height: 24px; /* Align height with the input */
	margin: 2px 0; /* Optional: Adjust spacing between buttons */
}

.opacity-button:hover {
	color: var(--primary-color-dark);
}

.opacity-number-input {
	padding: 0;
	margin: 0;
	width: 100%; /* Occupy full container width */
	max-width: 50px;
	box-sizing: border-box;
	border: 1px solid var(--border-color);
	flex-shrink: 0;
	-moz-appearance: textfield; /* Firefox */
	appearance: none; /* Standard */
	text-align: center;
	height: 24px; /* Ensure a consistent height */
}

.opacity-number-input::-webkit-inner-spin-button,
.opacity-number-input::-webkit-outer-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

.theme-toggle {
	padding: 10px;
	border-radius: 50%;
	background-color: rgba(255, 255, 255, 0.1);
	color: white;
	border: 1px solid rgba(255, 255, 255, 0.3);
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 40px;
	height: 40px;
	transition: all 0.2s ease;
}

.theme-toggle:hover {
	background-color: rgba(255, 255, 255, 0.2);
	border-color: rgba(255, 255, 255, 0.4);
}

/* Optionally, add performance enhancements */
.favicon {
	will-change: transform, opacity;
}
